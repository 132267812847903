import './App.css';
import { JSCharting, JSC } from 'jscharting-react';
import Button from '@mui/material/Button';


function Calendar(props) {
    const divStyle = {
        maxWidth: '700px',
        height: '4000px',
        margin: '0px auto'
    };
    if (!("first_ride" in props.data)) return null;

    function onPointClick(ev) {
      let opt = ev.currentTarget.currentOptions;
      props.showDetails(opt.eventids);
    }
    

    let config = { 
        type: 'horizontal calendar year solid', 
        calendar_calculation: 'average', 
        calendar: { 
            range: [props.data.first_ride, props.data.last_ride],
            weekStart: 0
          }, 
        legend: { 
          visible: false, 
        }, 
        palette: { 
          colors: [ 
            '#0000E3', 
            '#0047FF', 
            '#00ABFF', 
            '#0FFFEF', 
            '#43FFBB', 
            '#73FF8B', 
            '#A7FF57', 
            '#FFEF00', 
            '#FF8B00', 
            '#FF5700', 
            '#FF2300', 
            '#ED0000', 
            '#830000'
          ], 
        }, 
        title: { 
          label_text: 'Rides', 
          position: 'center', 
          style_fontSize: 16 
        }, 
        defaultTooltip_enabled: false, 
        yAxis_visible: false, 
        xAxis_line_visible: false, 
        series: [{
            points: []
        }],
        toolbar_visible: false
      }; 


      config.series = [{points:[]}];
      let fd = new Date(props.data.first_ride);
      let ed = new Date(props.data.last_ride);
      fd.setHours(0);
      fd.setMinutes(0);
      fd.setSeconds(0);
      ed.setHours(0);
      ed.setMinutes(0);
      ed.setSeconds(0);

      let activities = props.data.activities.map(d => [d.date.split("T")[0], d]).reduce((acc, val) => {
        if (!(val[0] in acc))
        {
          acc[val[0]] = [];  
        }
        acc[val[0]].push(val[1]);
        return acc;
      },{});
      let currentDay = new Date(fd.getFullYear(),0,1);
      let totalkm = 0;
      let delta = 500;
      let nextmilestone = delta;
      while (currentDay.getFullYear() === fd.getFullYear()) {
        var labelText = '<span style="align:right">'+JSC.formatDate(currentDay, 'dd')+'</span>';
        let inRange = (currentDay.valueOf() >= fd.valueOf() && currentDay.valueOf() <= ed.valueOf());

        let col = "lightgray";
        let eventids = [];
        if (inRange) {
          let datekey = currentDay.toISOString().split("T")[0];
            col = '#deecf9';
            if (datekey in activities)
            {
              var a = activities[datekey];
              eventids = a.map(v => v.id);
              let km = a.reduce((acc,val)=>acc+(val.distance/1000),0);

              col = "#004578";
              if (km <61) col = "#2b88d8";
              if (km <31) col = '#106ebe';
              
              totalkm += km;
              if (totalkm >= nextmilestone) {
                labelText = '<span style="align:left;color:#FF1111;font-weight:bold">'+nextmilestone+'</span>'+labelText;
                nextmilestone+=delta;
              }
              labelText += '<br/><span style="align:center;font-size:12px; font-weight:normal">' + Math.round(km) + 'km</span><br/>';
              labelText = labelText + a.reduce((acc,v) => {
                let name = v.name.replace(/[^0-9a-zA-Z ]/g,"_").substr(0,20);
                return acc+"<span style='paddingLeft:3px;font-size:9px;font-weight:normal;overflow:hidden'>"+name+"</span><br/>";
                },"");

              }
          }
        config.series[0].points.push({ 
            date: [currentDay, currentDay],
            eventids: eventids,
            events: {
              click: onPointClick
            },
            label: { 
              text: labelText,
              verticalAlign: 'top',
              padding: "3px", 
              autoHide: false,
              align: 'left',
            } ,
            color: col,
          });
        let nd = new Date(currentDay);
        nd.setDate(nd.getDate() + 1);
        currentDay = nd;
      }
      
    if (props.data.length === 0) {
        return null; 
    } else {
        return <div style={divStyle}><JSCharting options={config} mutable={false}/></div>;
    }
}

export default Calendar;

