import './App.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const itemStyle = {
    height: "70px"
};

function Summary(props) {
    if (props.data === null) return null;
    if (props.data.activities.length === 0) return null;

    function Item(props) {
        return (
            <Paper style={itemStyle}>
                <Typography variant="button">{props.label}</Typography>
                <Typography variant="h4">{props.val}</Typography>
            </Paper>
        );
    }

    var years = [];
    var thisyear = (new Date()).getFullYear();
    for (var i = 2021; i <= thisyear; i++) years.push(i);
    return (
       <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Paper style={itemStyle}>
                            <Select style={{marginTop:"5px"}} id="year" value={props.year} onChange={props.onChangeYear}>
                                {years.map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                            </Select>
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Item label="Total distance" val={props.data.total_km + "km"}/>
                </Grid>
                <Grid item xs={2}>
                <Item label="Total time" val={props.data.total_time + "h"}/>
                </Grid>
                <Grid item xs={2}>
                <Item label="Total Rides" val={props.data.total_rides}/>
                </Grid>
                <Grid item xs={2}>
                <Item label="First Ride" val={props.data.first_ride.split("T")[0]}/>
                </Grid>
                <Grid item xs={2}>
                <Item label="Last Ride" val={props.data.last_ride.split("T")[0]}/>
                </Grid>
            </Grid>
        </Box>        
    );
}

export default Summary;

