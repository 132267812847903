import './App.css';
import Chart from './Chart.js';
import Summary from './Summary.js';
import Calendar from './Calendar.js';
import ActivityView from './ActivityView.js';
import React, { useState, useEffect, useCallback } from "react";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';



function MainContent(props) {
  const { window } = props;
  const [[data, kmPoints, avgPoints], setData] = useState([{activities: []},null,null]);
  const [year, setYear] = useState(2023);

  function changeYear(year) {
    setYear(year);
    setData([{activities: []},null,null]);
    fetch("./data/data"+year+".json?cache_bust="+Date.now()).then(response => {return response.json();}).then(data =>  {
      let kmPointsTmp = data.activities.map(d => [d.date.split("T")[0], d.distance/1000]).reduce((acc, val) => {
        if (!(val[0] in acc))
        {
          acc[val[0]] = 0;  
        }
        acc[val[0]] += val[1];
        return acc;
      },{});
    
      let kmPoints = [];
      for (var k of Object.keys(kmPointsTmp)) {
        kmPoints.push([k, kmPointsTmp[k]]);
      }
    
      let avgPointsTmp = data.activities.map(d => [d.date.split("T")[0], d.avg]).reduce((acc, val) => {
        if (!(val[0] in acc))
        {
          acc[val[0]] = [];  
        }
        acc[val[0]].push(val[1]);
        return acc;
      },{});
    
      let avgPoints = [];
      for (var k of Object.keys(avgPointsTmp)) {
        let p = avgPointsTmp[k];
        avgPoints.push([k, p.reduce((a,v)=>a+v,0)/p.length]);
      }
        setData([data, kmPoints, avgPoints]);
    });
  }

  useEffect(() => {
    changeYear(year);
  }, []);


  if (kmPoints === null) return <Typography variant="h2">Loading</Typography>;

  return (
      <Grid container>
        <Grid item xs={12}>
          <Summary data={data} year={year} onChangeYear={(ev)=>changeYear(ev.target.value)}/>
        </Grid>
        <Grid item xs={6}>
          <Calendar data={data} year={year} showDetails={props.onDetails}/> 
        </Grid>
        <Grid item xs={6}>
          <Chart data={kmPoints} text="km per day"/>
          <Chart data={avgPoints} text="Avg km/h per day"/>
        </Grid>
      </Grid>
  );
}

const MainContentMemo = React.memo(MainContent);


function App({ children }) {
  const [details, setDetails] = useState([]);

  // using "useCallback" will make a fixed copy of the function. So the function wont change when passing to MainContentMemo, so it wont re-render
  const showDetails = useCallback((ids) => setDetails(ids), []);

  function handleDrawerToggle() {
    setDetails([]);
  }


  return (
    <div className="App">
      <Dialog fullWidth maxWidth="md" onClose={handleDrawerToggle} open={details.length > 0}>
        <DialogTitle>Activities Details</DialogTitle>
        <ActivityView ids={details} />
      </Dialog>
      <MainContentMemo onDetails={showDetails} />
    </div>
    );
}


export default App;
