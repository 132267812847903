import './App.css';
import { JSCharting } from 'jscharting-react';

function Chart(props) {
    console.log("chart render");
    const divStyle = {
        maxWidth: '700px',
        height: '400px',
        margin: '0px auto'
    };

    let config = {
        type: 'line',
        legend: {
            visible: false
        },
        title_label_text: props.text,
        options: {
            scales: {
                x: {
                ticks: {
                    display: false
                }
            }
            }
        },
        xAxis: { 
            scale_type: 'time',
            visible: false,
            scale: {
                interval: {
                    unit: "day",
                    multiplier: 14
                },
                minorInterval: {
                    unit: "day",
                    multiplier: 14
                }
            }
        },
        series: [{
            points: props.data
        }]
    };

    if (props.data.length === 0) {
        return null; 
    } else {
        return <div style={divStyle}><JSCharting options={config} mutable={false}/></div>;
    }
}

export default Chart;

