import './App.css';
import React, { useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import {decode } from '@mapbox/polyline';

const style = {
    width: "800px",
    //backgroundColor: "red",
    height: "2000px"
}

function ActivityView(props) {
    const [activities, setActivities] = useState([]);
    const [currentActivityIndex, setcurrentActivityIndex] = useState(0);


    useEffect(() => {
        var alist = props.ids.map(id => new Promise((resolve, reject)=> {
            fetch("./data/activity-"+id+".json?cache_bust="+Date.now()).then(response => {return response.json();}).then(data =>  {
                resolve(data);
            });
        }));
        Promise.all(alist).then(values => {
            setActivities(values);
        });
    },[]);

    const handleTabChange = (ev, val) => {
        setcurrentActivityIndex(val);
    }

    console.log(activities);
    if (activities.length === 0) return null;

    function SegmentTableRow(props) {

        return <TableRow>
            <TableCell><Typography variant="subtitle2">{props.data.name}</Typography></TableCell>
            <TableCell>{Math.round(10*props.data.distance/1000)/10 + " km"}</TableCell>
            <TableCell>{Math.round(10*props.data.moving_time/60)/10 + " min"}</TableCell>
            <TableCell><Rating readOnly name="PR" defaultValue={4-(props.data.pr_rank || 4)} max={3} /></TableCell>
            
        </TableRow>;
    }
    function Item(props) {
        return (
            <React.Fragment>
                <Grid item xs={6}><Typography variant="subtitle2">{props.label}</Typography></Grid>
                <Grid item xs={6}><Typography variant="body1">{props.value}</Typography></Grid>
            </React.Fragment>
        );
    }

    let currentActivity = activities[currentActivityIndex];
    if (!currentActivity.map) return "No details for this activity";
    let poly = decode(currentActivity.map.polyline);
    let boundRect = {top:999999, bottom:-99999, left:99999, right: -99999}
    let midX=0;
    let midY=0;
    if (!poly) {
        poly=[];
        midX= 0;
        midY=0;
    } else {
    poly.forEach(p => {
        if (p[0] > boundRect.bottom) boundRect.bottom = p[0];
        if (p[0] < boundRect.top) boundRect.top = p[0];
        if (p[1] < boundRect.left) boundRect.left = p[1];
        if (p[1] > boundRect.right) boundRect.right = p[1];
    });
    midY  = boundRect.top+(boundRect.bottom-boundRect.top)/2;
    midX  = boundRect.left+(boundRect.right-boundRect.left)/2;
    console.log(boundRect);
    }
    return (
        <div style={style}>
            <Tabs onChange={handleTabChange} value={currentActivityIndex}  indicatorColor="primary">
            { activities.map((a,i) => <Tab value={i} label={a.name} />) }
            </Tabs>
            <Grid container style={{padding:"10px"}}>
            <Grid item xs={6}>
                <Grid container>
                <Item label="Distance" value={Math.round(10*currentActivity.distance/1000)/10 + " km"} />
                <Item label="Moving Time" value={Math.round(10*currentActivity.moving_time/60)/10 + " min"} />
                <Item label="Start" value={(new Date(currentActivity.start_date_local)).toLocaleString()} />
                <Item label="Avg. Speed" value={Math.round(10*currentActivity.average_speed/0.36)/10 + " km/h"} />
                <Item label="Max speed" value={Math.round(10*currentActivity.max_speed/0.36)/10 + " km/h"} />
                <Item label="Average Cadence" value={currentActivity.average_cadence} />
                <Item label="Power" value={currentActivity.average_watts +" watts"} />
                <Item label="Highest Elevation" value={currentActivity.elev_high+" m"} />
                <Item label="Lowest Elevation" value={currentActivity.elev_low+" m"} />
                <Item label="Calories" value={currentActivity.calories} />
                <Item label="Achievements" value={currentActivity.achievement_count} />
                </Grid>
            </Grid>
            <Grid item xs={6}>

                <MapContainer center={[midY, midX]} zoom={9} >
                    <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Polyline pathOptions={{color:"blue"}} positions={poly} />
                </MapContainer>
                </Grid>
            <Grid item xs={12}>
                <Table sx={{ minWidth: 650 }} aria-label="Segments">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Distance</TableCell>
                        <TableCell>Moving Time</TableCell>
                        <TableCell>Rank</TableCell>
                    </TableRow>
                 </TableHead>
                 {currentActivity.segment_efforts.map(e => <SegmentTableRow data={e} />)}
                </Table>
            </Grid>
            </Grid>
            
        </div>
    )
}

export default ActivityView;

